<script>

export default {
	name: 'form-container',
	components: {
	},

	props: {
		submitWithReturn: Boolean,
		error: String
	},

	methods: {
		submit() {
			this.$emit("submit");
		}
	}
};

</script>

<template>
	<form class="form" @submit.prevent="submit" autocomplete="off" >
		<div v-if="error" class="error" v-html="error"/>
		<slot/>
		<input v-if="submitWithReturn" type="submit" style="display:none;"/>
	</form>
</template>

<style lang="scss">
	.form{
        margin-bottom: $padding;

        &:last-child {
            margin-bottom: 0;
        }

        &.no-padding {
            margin-bottom: 0;
        }
	}

    .error {
        padding-left: 0;
        color: $red;
        padding-bottom: $pad2;
        font-size: 12px;
        border-color: $red;
    }

    .input {
        position: relative;
    }

    .form-group{
        margin-bottom: $pad2;
        position: relative;

        &.grey {
            .form-text, .form-select, .form-textarea {
                background: $lightgrey2;
                border: 0;
                color: $darkgrey;
                font-weight: $medium;
            }
        }

        &.group-stuck {
            &:not(.form-check) {
                flex-direction: column;
            }
            &.form-check {
                input {
                    cursor: pointer;
                }

                label {
                    padding: 10px $pad3;
                    margin-bottom: 0;
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        color: white;
                    }
                }
            }

            .input {
                display: flex;
                flex-grow: 1;

                input, select, textarea {
                    flex-grow: 1;
                }
            }

            .error {
                position: absolute;
                left: 0;
                bottom: 100%;
                background-color: red;
                color: #FFFFFF;
                font-weight: $bold;
                padding: 5px 10px 7px 10px;
                border-radius: 4px;
                margin-bottom: 5px;
                max-width: 100%;

                span {
                    position: relative;
                    //z-index: 2;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    bottom: -5px;
                    left: 10px;
                    transform: rotateZ(45deg);
                    //z-index: 1;
                }
            }
        }
        label{
            font-size: inherit;
            display: block;
            margin-bottom: $pad4;
            user-select: none;

            sup {
                font-size: 0.8em;
                position: relative;
                top: -4px;
            }
        }
        .label-stuck{
            height:50px;
            line-height: 50px;
            border-radius:10px 0 0 10px;
            padding: 0 15px 0 0;
            border-right: 1px solid #ccc;
            margin-bottom: 0;
            white-space: nowrap;
        }
        &.form-error{
            label{
                color:$red;
            }
            select:not(.no-error), textarea, input{
                color:$red !important;
                border:1px solid $red !important;
            }
            .vti__dropdown {
                border:1px solid $red;
            }
        }
        &.form-valid{
            label{
                color:#66ab44;
            }
            input{
                background-color: #ebf9e4;
                color:#66ab44;
                border:1px solid #e0eeda;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }

        .meta {
            color: $red;
            padding-top: 8px;
            font-size: 11px;
        }
        .Icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            &, svg {
                fill: $grey;
            }

            & + input {
                padding-right: $padding;
            }
        }

        .disclaimer {
            font-size: 11px;
            color: $grey;
            margin-bottom: math.div($padding, 6);
        }
    }
    .label {
        display: flex;
        align-items: center;
        margin-bottom: $pad4;
        color: $blue;
        label {
            margin-bottom: 0;
        }
    }
    .form-text, .form-text-input-input{
        font-size: 14px;
        height: 30px;
        border-radius: 30px;
        border: 1px solid $blue;
        color: $blue;
        padding-left: $pad3;
        display: inline-block;
        width: 100%;

        &[readonly] {
            border: none;
        }
    }
    .form-text[type='date'] {
        text-align: center;
        color: $blue;
        border: 1px solid $blue;
        font-weight: $bold;
        &::-webkit-calendar-picker-indicator,
        &::-webkit-inner-spin-button {
            color: $white;
            path {
                fill: $white;
            }
        }
    }
    .form-textarea{
        font-size: 14px;
        min-height: 85px;
        padding: 10px;
        display: inline-block;
        width: 100%;
        border: 1px solid $blue;
        color: $blue;
        border-radius: 30px;
    }
    .select {
        background: white;
        position: relative;
        border-radius: 20px;
        .form-select{
            font-size: 13px;
            display: inline-block;
            width: 100%;
            color: $blue;
            height: 30px;
            background: transparent;
            font-weight: $medium;
            position: relative;
            z-index: 1;
            border: 1px solid $blue;
            padding: math.div($padding, 8) $pad3;
            border-radius: 30px;
            appearance: none;
            option[disabled="disabled"] {
                color: $grey;
            }
        }

        .icon {
            z-index: 0;
        }
    }

    .form-check, .form-rad{
        display: inline-flex;
        align-items: center;
        .form-checkbox, .form-radio{
            float: left;
            margin-right: $pad2;
            border: 1px solid $grey;
        }
    }
    .form-rad {
        display: flex;
        align-items: center;
        margin-bottom: $pad4 !important;
        .form-radio-label {
            margin-bottom: 0 !important;
        }
    }
    .form-check-label {
        margin-bottom: 0 !important;
    }
    input, select{
        outline: none;
    }
    button{
        border: none;
    }
    ::-webkit-input-placeholder { /* Edge */
        font-size: 14px;
        color: $lightgrey;
        font-weight: $regular;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 14px;
        color: $lightgrey;
        font-weight: $regular;
    }
    ::placeholder {
        font-size: 14px;
        color: $lightgrey;
        font-weight: $regular;
    }

    .vue-tel-input {
        position: relative;
        border-radius: 30px !important;
        border: 1px solid $blue;
        color: $blue;

        &:focus-within{
            box-shadow: none!important;
        }

        .vti__dropdown {
            border-bottom-left-radius: 30px;
            border-top-left-radius: 30px;
            background: $white;
            border-right: 1px solid $blue;
            position: static;

            .vti__dropdown-list.below {
                width: 100%;
                top: 31px;
            }
        }
        .vti__input {
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            font-size: 14px;
            height: 30px;
            padding-left: $pad2;
            display: inline-block;
            border: none;
            width: 100%;
            color: $blue;
        }

        &:focus-within {
            border-color: $blue;
        }
    }

    .form-group.grey {
        .vti__input, .vti__dropdown {
            background: $lightgrey2;
        }
        .vti__dropdown {
            border-right-color: $white;
        }
    }

    .VuePassword__Input {
        font-size: 14px;
        height: 30px !important;
        border-radius: 30px !important;
        border: 1px solid $blue !important;
        color: $blue !important;
        padding-left: $pad2;
        display: inline-block;
        width: 100%;
    }

</style>
