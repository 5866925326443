import {Commit, Dispatch} from "vuex";
import {
	IBus,
	IBusDB, ICompanyDB,
	IDepot,
	IDepotDB,
	IManager,
	IRootState,
	ITravel,
	ITravelDB
} from "@/data/types";
import moment from "moment-timezone";

const actions = {
    // --------------------------------------------
    // PROFILE
    // --------------------------------------------

    getProfile({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, companyId?:string) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/user/profile", "GET")
                .then(result => {
                    setUser(resolve, commit, {companyId, result});
                })
                .catch(() => {
                    commit('auth/logout', {root: true});
                    reject();
                })
        });
    },
    getUserNotifications({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/user/notification", "GET")
                .then(result => {
                    setNotifications(resolve, commit, result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
	readNotifications({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, notificationId: string) {
		return request(dispatch, '/notification/' + notificationId + '/read', 'PUT');
	},
    saveUserProfile({dispatch, commit, rootState}: { dispatch: Dispatch, commit: Commit , rootState: IRootState}, data: any) {
        return request(dispatch, "/user/profile", 'PUT', data);
    },
	updateUserTerms({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
		return request(dispatch, "/user/terms", 'PUT', data);
	},
    getGlobalConfiguration({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/configuration", "GET")
                .then(result => {
                    setConfiguration(resolve, commit, result);
                })
                .catch(err => {
                    reject(err);
                })
        });
    },
    updateCompany({dispatch, commit}: { dispatch: Dispatch, commit: Commit },  data:any) {
        return request(dispatch, "/company/" + data._id, 'PUT', data);
    },
    getOtherCompany({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, companyId:string) {
        return request(dispatch, "/company/" + companyId, 'GET');
    },

	// System Messages
	getNewestSystemMessage({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
		console.log('Getting newest message vie API')
		return new Promise((resolve, reject) => {
			request(dispatch, "/system_message/newest", "GET")
				.then(result => {
					console.log("Got result: ", result)
					setNewestSystemMessage(resolve,commit, {result});
				})
				.catch(() => {
					console.log("Promise error")
					commit('app/accueil', {root: true});
					reject();
				})
		});
	},
	// Set Message
	readSystemMessage({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
		return request(dispatch, '/user/message-seen', 'PUT', data);
	},


    // travel

    getMyTravels({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel", 'GET', null, params);
    },
    getTravelsToDo({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/todo", 'GET', null, params);
    },
    createTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, travel: ITravel) {
        return request(dispatch, "/travel", 'POST', travel);
    },
    searchTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/search", 'GET', null, params);
    },
    getTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, travelId: string) {
        return request(dispatch, "/travel/" + travelId, 'GET');
    },
    updateTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, travel: ITravelDB) {
        return request(dispatch, "/travel/" + travel._id, 'PUT', travel);
    },
    sendTravelCommand({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/travel/" + data.travelId + "/command/" + data.command, 'POST', data.data);
    },
    getTravelsForBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/available", 'GET', null, params);
    },
    getHistory({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/history", 'GET', null, params);
    },
    getHistoryCsv({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/history/csv", 'GET', null, params, "blob");
    },
    getHistoryInvoice({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/history/invoice", 'GET', null, params, "blob");
    },
    getTravelDistance({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/travel/distance", 'GET', null, params);
    },
	getPaymentDocument({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
		return request(dispatch, "/travel/" + data.travelId + "/payment/" + data.paymentId + "/doc/" + data.type, 'GET', null, {ts: moment.now()}, "blob");
	},

    // proposal

    createProposal({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data:any) {
        return request(dispatch, "/proposal/travel/" + data.travelId, 'POST', data.data);
    },
    sendProposalCommand({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/proposal/" + data.proposalId + "/command/" + data.command, 'POST', data.data);
    },
    getProposals({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, "/proposal", 'GET');
    },
    editProposal({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data:any) {
        return request(dispatch, "/proposal/" + data.proposalId + "/command/edit", 'POST', data.data);
    },
    addAmendment({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data:any) {
        return request(dispatch, "/proposal/" + data.proposalId + "/command/addAmendment", 'POST', data.data);
    },
    readProposal({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, proposalId: string) {
        return request(dispatch, "/proposal/" + proposalId + "/read", 'POST');
    },
    getUnreadMessageNb({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return new Promise((resolve, reject) => {
            request(dispatch, "/proposal/message/unread", 'GET')
                .then(result => {
                    setMessageNumber(resolve, commit, result);
                })
                .catch(err => {
                    commit('auth/logout', {root: true});
                    reject(err);
                })
        });
    },


    // bus

    createBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, bus: IBus) {
        return request(dispatch, "/bus", 'POST', bus);
    },
    updateBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, bus: IBusDB) {
        return request(dispatch, "/bus/" + bus._id, 'PUT', bus);
    },
    listBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params: any) {
        return request(dispatch, "/bus", 'GET', null, params);
    },
    getBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, busId: string) {
        return request(dispatch, "/bus/" + busId, 'GET');
    },
    getBusAvailabilities({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/availability", 'GET', null, data.params);
    },
    createBusAvailabilities({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/availability", 'POST', data);
    },
    addBusAvailability({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/availability", 'POST', data.data);
    },
    editBusAvailability({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/availability/" + data.avId, 'PUT', data.data);
    },
    removeBusAvailability({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/availability/" + data.avId, 'DELETE');
    },
    getAvailableBuses({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params:any) {
        return request(dispatch, "/bus/availables", 'GET', null, params);
    },
    getAvailableBusesForTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, travelId:string) {
        return request(dispatch, "/travel/" + travelId + "/bus", 'GET');
    },
    searchBus({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, params:any) {
        return request(dispatch, "/bus/search", 'GET', null, params);
    },
    busProposeTravel({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
        return request(dispatch, "/bus/" + data.busId + "/propose", 'POST', data.data);
    },


    // depot

    createDepot({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, depot: IDepot) {
        return request(dispatch, "/company/" + rootGetters.currentCompany._id + "/depot", 'POST', depot);
    },
    updateDepot({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, depot: IDepotDB) {
        return request(dispatch, "/company/"  +  rootGetters.currentCompany._id + "/depot/" + depot._id, 'PUT', depot);
    },
    removeDepot({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, depotId: string) {
        return request(dispatch, "/company/"+ rootGetters.currentCompany._id +"/depot/" + depotId, 'DELETE');
    },

    // manager

    createManager({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, manager: IManager) {
        return request(dispatch, "/company/"+ rootGetters.currentCompany._id +"/manager", 'POST', manager);
    },
    updateManager({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, manager: any) {
        return request(dispatch, "/company/"+ rootGetters.currentCompany._id +"/manager/" + manager.managerId, 'PUT', manager.manager);
    },
    removeManager({dispatch, commit, rootGetters}: { dispatch: Dispatch, commit: Commit, rootGetters:any }, managerId: string) {
        return request(dispatch, "/company/"+ rootGetters.currentCompany._id +"/manager/" + managerId, 'DELETE');
    },

    // dashboard

    getDashboardPrincipal({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, "/dashboard/principal", 'GET');
    },
    getDashboardSubcontractor({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, "/dashboard/subcontractor", 'GET');
    },
    getDashboardStatistics({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, "/dashboard/statistics", 'GET');
    },

    getLegals({dispatch, commit}: { dispatch: Dispatch, commit: Commit }) {
        return request(dispatch, "/website/legals", "GET");
    },
    applyContact({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data:any) {
        return request(dispatch, "/website/contact", "POST", data);
    },

	// ----------------------------------------------------------------------------------------------------
	// BOOKING
	// ----------------------------------------------------------------------------------------------------

	bookingRejectOrder({dispatch, commit}: { dispatch: Dispatch, commit: Commit }, data: any) {
		return request(dispatch, `/booking/order/${data.orderId}/reject`, 'POST', data);
	},
};

export default {
    namespaced:  true,
    actions,
};

// PRIVATES ------------------

function fake(obj: any, key?: string) {
    return new Promise((resolve, _reject) => {
        setTimeout(() => {
            if (key) {
                resolve({[key]: obj});
            } else {
                resolve(obj);
            }
        }, 300);
    });
}

function request(dispatch: Dispatch, to: string, method: string, data?: any, params?: any, responseType:string = "json") {
    return dispatch(
        "send",
        {
            to,
            method,
            data,
            params,
            responseType
        },
        {root: true}
    );
}

function setUser(resolve: any, commit: Commit, result: any) {
    commit("setUser", result.result, {root: true});
    commit("setCurrentCompany", (result.companyId && result.result.companies.find((c: ICompanyDB) => c._id === result.companyId)) || result.result.companies[0], {root: true});
    resolve(result);
}

function setNewestSystemMessage(resolve : any, commit: Commit, result: any) {
	console.log("Entered ")
	commit("setNewestSystemMessage", result.result, {root: true});
	resolve(result.result);

}


function setConfiguration(resolve: any, commit: Commit, result: any) {
    commit("setConfiguration", result, {root: true});
    resolve(result);
}

function setNotifications(resolve: any, commit: Commit, result: any) {
    commit("setNotifications",  result , {root: true});
    resolve(result);
}

function setMessageNumber(resolve: any, commit: Commit, result: any) {
    commit("setMessageNumber",  result , {root: true});
    resolve(result);
}
