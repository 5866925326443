import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import ROUTES from "@/data/routes";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: '__root',
        redirect: 'app'
    },
    {
        path: '/logas',
        name: ROUTES.LOG_AS,
        component: () => import(/* webpackChunkName: "log-as" */ '../views/LogAs.vue'),
        meta: {
            noAuthentication: true
        }
    },
    {
        path: "/connexion",
        name: ROUTES.LOGIN,
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: "/nouveau-mot-de-passe",
        name: ROUTES.RESET_PASSWORD,
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue'),
        meta: {
            noAuthentication: true
        }
    },
    {
        path: '/app',
        name: ROUTES.APP._ROOT,
        redirect: {name: ROUTES.APP.HOME},
        component: () => import(/* webpackChunkName: "layout" */ '../views/app/Layout.vue'),
        children: [
            {
                path: 'accueil',
                name: ROUTES.APP.HOME,
                component: () => import(/* webpackChunkName: "home" */ '../views/app/Home.vue'),
            },
            {
                path: 'trajets-a-faire',
                name: ROUTES.APP.TRAVELS_TO_DO,
                component: () => import(/* webpackChunkName: "sub-contractor-travels-to-do" */ '../views/app/SubContractor/TravelsToDo.vue'),
            },
            {
                path: 'mes-vehicules-disponibles',
                name: ROUTES.APP.AVAILABLE_BUSES,
                component: () => import(/* webpackChunkName: "sub-contractor-available-bus" */ '../views/app/SubContractor/AvailableBuses.vue'),
            },
            {
                path: 'chercher-un-trajet',
                name: ROUTES.APP.SEARCH_TRAVEL,
                component: () => import(/* webpackChunkName: "sub-contractor-search-travel" */ '../views/app/SubContractor/SearchTravel.vue'),
            },
            {
                path: 'mes-trajets',
                name: ROUTES.APP.MY_TRAVELS,
                component: () => import(/* webpackChunkName: "principal-my-travels" */ '../views/app/Principal/MyTravels.vue'),
            },
            {
                path: 'chercher-un-vehicule-proche',
                name: ROUTES.APP.SEARCH_BUS,
                component: () => import(/* webpackChunkName: "principal-search-bus" */ '../views/app/Principal/SearchBus.vue'),
            },
            {
                path: 'trajet/:travelId',
                name: ROUTES.APP.TRAVEL._ROOT,
                component: () => import(/* webpackChunkName: "travel" */ '../views/app/Travel/TravelLayout.vue'),
                redirect: {name: ROUTES.APP.TRAVEL.MESSAGING},
                children: [
                    {
                        path: 'messagerie',
                        name: ROUTES.APP.TRAVEL.MESSAGING,
                        component: () => import(/* webpackChunkName: "travel-messaging" */ '../views/app/Travel/Messaging.vue'),
                    },
                    {
                        path: 'vehicules',
                        name: ROUTES.APP.TRAVEL.BUSES,
                        component: () => import(/* webpackChunkName: "travel-buses" */ '../views/app/Travel/Buses.vue'),
                    },
                    {
                        path: 'factures',
                        name: ROUTES.APP.TRAVEL.BILLING,
                        component: () => import(/* webpackChunkName: "travel-billing" */ '../views/app/Travel/Billing.vue'),
                    },
                ]
            },
            {
                path: 'vehicule/:busId',
                name: ROUTES.APP.BUS._ROOT,
                component: () => import(/* webpackChunkName: "bus" */ '../views/app/Bus/BusLayout.vue'),
                redirect: {name: ROUTES.APP.BUS.AVAILABILITIES},
                children: [
                    {
                        path: 'disponibilitees',
                        name: ROUTES.APP.BUS.AVAILABILITIES,
                        component: () => import(/* webpackChunkName: "bus-availabilities" */ '../views/app/Bus/Availabilities.vue'),
                    },
                    {
                        path: 'documents',
                        name: ROUTES.APP.BUS.DOCUMENTS,
                        component: () => import(/* webpackChunkName: "bus-documents" */ '../views/app/Bus/Documents.vue'),
                    },
                    {
                        path: 'photos',
                        name: ROUTES.APP.BUS.PICTURES,
                        component: () => import(/* webpackChunkName: "bus-pictures" */ '../views/app/Bus/Pictures.vue'),
                    },
                    {
                        path: 'trajets',
                        name: ROUTES.APP.BUS.TRAVELS,
                        component: () => import(/* webpackChunkName: "bus-travels" */ '../views/app/Bus/Travels.vue'),
                    },
                ]
            },
            {
                path: 'entreprise',
                name: ROUTES.APP.COMPANY._ROOT,
                component: () => import(/* webpackChunkName: "company" */ '../views/app/Company/CompanyLayout.vue'),
                redirect: {name: ROUTES.APP.COMPANY.PROFILE},
                children: [
                    {
                        path: 'profil',
                        name: ROUTES.APP.COMPANY.PROFILE,
                        component: () => import(/* webpackChunkName: "company-profile" */ '../views/app/Company/Profile.vue'),
                    },
                    {
                        path: 'gestion-de-la-flotte',
                        name: ROUTES.APP.COMPANY.BUS_MANAGEMENT,
                        component: () => import(/* webpackChunkName: "company-bus-management" */ '../views/app/Company/BusManagement.vue'),
                    },
                    {
                        path: 'historique-trajets',
                        name: ROUTES.APP.COMPANY.TRAVEL_HISTORY,
                        component: () => import(/* webpackChunkName: "company-travel-history" */ '../views/app/Company/TravelHistory.vue'),
                    },
                ]
            },
            {
                path: 'profil',
                name: ROUTES.APP.PROFILE,
                component: () => import(/* webpackChunkName: "profile" */ '../views/app/Account/Profile.vue'),
            },
            {
                path: 'notifications',
                name: ROUTES.APP.NOTIFICATIONS,
                component: () => import(/* webpackChunkName: "profile" */ '../views/app/Account/Notifications.vue'),
            },
            {
                path: 'messagerie',
                name: ROUTES.APP.MESSAGING,
                component: () => import(/* webpackChunkName: "messaging" */ '../views/app/Messaging.vue'),
            },
            {
                path: 'cgvu',
                name: ROUTES.APP.LEGALS,
                component: () => import(/* webpackChunkName: "legals" */ '../views/app/Legals/Legals.vue'),
            },
            {
                path: 'contact',
                name: ROUTES.APP.CONTACT,
                component: () => import(/* webpackChunkName: "contact" */ '../views/app/Legals/Contact.vue'),
            },
            {
                path: 'cgv',
                name: ROUTES.APP.CGV,
                component: () => import(/* webpackChunkName: "cgv" */ '../views/app/Legals/Cgv.vue'),
            },
        ],
    }
];

console.log(routes.map(r => {
    return {
        name: r.name,
        path: r.path,
        children: r.children
    };
}))

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
})

export default router
