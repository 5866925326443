import Vue from "vue";

import {capitalize} from "@/helpers/commons";
import moment from "moment-timezone";
import {formatPrice} from "fullbus-pricing";

Vue.filter('capitalize', (value: string) => {
    return capitalize(value);
});

Vue.filter('img', (value: string) => {
    return process.env.VUE_APP_API_STATIC_URL + value;
});

Vue.filter('currency', (value: any) => {
	return formatPrice(value, " €", {thousandSeparator: " "});
});

Vue.filter('when', (value: number) => {
    return moment(value).format('DD/MM/YY - HH[h]mm');
})

Vue.filter("phone", (value: string) => {
	let result: string[] = [];
	let next = 2;


	if(value.startsWith("+")) {
		result.push(value.substring(0 , 3));
		value = value.substring(3);
		next = 1;
	}

	let parts = "";
	for(let i = 0, n = value.length; i < n; ++i) {
		parts += value.charAt(i);
		if(parts.length === next) {
			result.push(parts);
			parts = "";
			next = 2;
		}
	}
	if(parts.length > 0) {
		result.push(parts);
	}
	return result.join(" ");
})
