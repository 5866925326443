<script>
export default {
    name: "InputGmapAutocomplete",
    data() {
        return {
            cur_value: '',
            place: {}
        };
    },
    props: ['label', 'name', 'error', 'value', 'placeholder', 'disabled', 'classes'],
    computed: {},
    methods: {
        place_changed(value) {
			console.log("PLACE CHANGED FROM GOOGLE: ", value);
            this.place.name = value.name;
            this.place.formatted_address = value.formatted_address;
            this.place.latitude = value.geometry.location.lat();
            this.place.longitude = value.geometry.location.lng();
            this.place.geometry = value.geometry;
            this.place.place_id = value.place_id;

            value.address_components.forEach(cmp => {
                cmp.types.forEach(type => {
                    switch (type) {
                        case "county":
                        case "country":
                            this.place.country = cmp.long_name;
                            this.place.countryCode = cmp.short_name;
							break;
                        case "postal_code":
                        case "locality":
                        case "route":
                        case "formatted_address":
                        case "latitude":
                        case "longitude":
                        case "street_number":
                            this.place[type] = cmp.long_name;
                            break;
						case "administrative_area_level_2":
							this.place.department = cmp.long_name;
							break;
						case "administrative_area_level_1":
							this.place.region = cmp.long_name;
							break;
                    }
                })
            });
            this.$emit('placeChanged', this.place);
        }
    },
    watch: {
        value(n) {
            this.cur_value = n;
        }
    },
    beforeMount() {
    },
    updated() {
        if (typeof this.value !== "undefined") {
            this.cur_value = this.value;
        }
    },
    mounted() {
        if (typeof this.value !== "undefined") {
            this.cur_value = this.value;
        }
    },
    created() {
    }
};
</script>
<template>
    <div class="inputGmapAutocomplete">
        <div :class="`form-group ${classes} ${error ? 'errorgmap' : ''}`">
            <label v-show="label" :for="name" class="label">{{ $t(label) }}</label>
            <gmap-autocomplete
                ref="gmap" class="form-text GmapInput"
                :id="name"
                :name="name"
                :autocomplete="'off'"
                :placeholder="placeholder"
                :value="value"
                :disabled="disabled"
                @place_changed="place_changed"
            />
            <!--            <div class="fields">-->
            <!--                <field v-if="place.route" class="read" type="read" :label="$t('form.name')" :value="place.route"/>-->
            <!--                <field v-if="place.postal_code" class="read" type="read" :label="$t('form.zip')" :value="place.postal_code"/>-->
            <!--                <field v-if="place.locality" class="read" type="read" :label="$t('form.city')" :value="place.locality"/>-->
            <!--            </div>-->
            <collapse-transition mode="out-in">
                <error v-if="error" :error="error"/>
            </collapse-transition>
        </div>
    </div>
</template>
<style lang="scss">
.inputGmapAutocomplete {
    margin-bottom: $pad2;

    label {
    }

    .fields {
        margin-top: $pad2;
    }

    .form-group.read > .input {
        display: flex;
        justify-content: space-between;
        padding: 0 $pad2;
    }

    .form-group {
        margin-bottom: $padding;

        &:last-child {
            margin-bottom: 0;
        }

        .error {
            padding-top: 8px;
        }

        &.errorgmap {
            label {
                color: $red;
            }
            input {
                border-color: $red !important;
            }
        }

    }

    &.grey {
        input {
            background: $white;
            color: $blue;

            &::placeholder {
            }
        }
    }

}
</style>
