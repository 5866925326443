"use strict";

import PasswordValidator from "password-validator";
import moment from "moment-timezone";
import i18n from "@/i18n/i18n";
import {LabelValue} from "@/data/types";
import {validate as validateEmail} from "email-validator";
import {isValidPhoneNumber} from "libphonenumber-js";

export function getFormattedHour(hour: number) {
    const h = hour.toString();

    if (h.length === 3) {
        return moment(h, "Hmm").format(i18n.t('date.time2'));
    } else if (h.length === 4) {
        return moment(h, "HHmm").format(i18n.t('date.time2'));
    } else {
        return "";
    }
}

export function passwordStrength(password: string) {
    const schema1 = new PasswordValidator();
    const schema2 = new PasswordValidator();
    const schema3 = new PasswordValidator();
    const schema4 = new PasswordValidator();

    schema1.min(8);
    schema2.uppercase(1);
    schema3.symbols(1);
    schema4.digits(1);

    const valid1 = schema1.validate(password);
    const valid2 = schema2.validate(password);
    const valid3 = schema3.validate(password);
    const valid4 = schema4.validate(password);

    let score: number = 0;

    if (valid1) {
        score += 1;
    }
    if (valid2) {
        score += 1;
    }
    if (valid3) {
        score += 1;
    }
    if (valid4) {
        score += 1;
    }

    return score;
}

export function formatErrors(errors: any, to?: string) {
    let formatedErrors: any = {errors: {}, _global: [], data: null};

    if (errors && Array.isArray(errors)) {
        errors.forEach((err: any) => {
            if (err.id) {
                if (err.id === "Validation.EmailIsAlreadyUsed") {
                    err.context = "email";
                }
                if (typeof err.context === "string") {
                    if (err.context.indexOf('body.') !== -1) {
                        err.context = err.context.replace('body.', '');
                    }
                    formatedErrors.errors[err.context] = {};
                    formatedErrors.errors[err.context].id = err.id;
                    formatedErrors.errors[err.context].action = to || "";
                    formatedErrors.errors[err.context].key = err.context;
                } else {
                    formatedErrors._global.push({
                        id: err.id,
                        action: to
                    });
                }
            }
        });
    }

    return {
        errors: formatedErrors.errors || {},
        _global: formatedErrors._global || [],
        origin: errors
    };
}

export function formattedAddress(address: any) {
    if (address && (address.street || address.zip || address.city)) {
        return ((address.street && (address.street + (((address.zip || address.city) && ', ') || ''))) || '')
            + ((address.zip && (address.zip + ' ')) || '')
            + (address.city || '');
    }
    return '';
}

export function capitalize(string:string | undefined) {
    return string && (string.charAt(0).toUpperCase() + string.slice(1));
}

export function getNumeric(value: any) {
    if (isNaN(value)
        || value === null
        || value === undefined) {
        return '-'
    }
    return parseFloat(value);
}

export function getString(value: any) {
    return (value || '-').toString();
}

export function getDuration(value: any) {
    return moment.duration(value, 'millisecond').humanize();
}

export function isValidEmail(email:string) {
    return validateEmail(email);
}

export function isValidPhone(phone:string) {
    return isValidPhoneNumber(phone, 'FR');
}

export function formatHour(hour:number) {
    if (hour) {
        let h = hour.toString();

        if (h.length === 3) {
            return h.substr(0, 1) + "h" + h.substr(1, 2);
        }
        return h.substr(0, 2) + "h" + h.substr(2, 2);
    }
}

export function formatHourNumeric(hour:number) {
    let h = hour.toString();

    return (h.length === 1 ? '0' : '') + h;
}

export function paragraphize(text: string|Array<string>) : string|null {
    if(text) {
        let atext: Array<string> = Array.isArray(text)? text:text.split("\n");
        return atext.map((p: string) => {
            return "<p>" + p + "</p>";
        }).join("");
    }
    else {
        return null;
    }
}

export function serializeAddress(addr: any) {
    return {
        "formatted_address": addr.formatted_address,
        "street": addr.street,
        "zip": addr.zip,
        "city": addr.city,
        "country": addr.country,
        "countryCode": addr.countryCode,
        "location": addr.location
    }
}

export function isSameRoute(a:any, b:any) {
    const START = '/';
    const trailingSlashRE = /\/?$/;

    function isObjectEqual (a:any, b:any):any {
        if ( a === void 0 ) a = {};
        if ( b === void 0 ) b = {};

        // handle null value #1566
        if (!a || !b) { return a === b }
        var aKeys = Object.keys(a);
        var bKeys = Object.keys(b);
        if (aKeys.length !== bKeys.length) {
            return false
        }
        return aKeys.every(function (key) {
            var aVal = a[key];
            var bVal = b[key];
            // check nested equality
            if (typeof aVal === 'object' && typeof bVal === 'object') {
                return isObjectEqual(aVal, bVal)
            }
            return String(aVal) === String(bVal)
        })
    }

    if (b === START) {
        return a === b
    } else if (!b) {
        return false
    } else if (a.path && b.path) {
        return (
            a.path.replace(trailingSlashRE, '') === b.path.replace(trailingSlashRE, '') &&
            a.hash === b.hash &&
            isObjectEqual(a.query, b.query)
        )
    } else if (a.name && b.name) {
        return (
            a.name === b.name &&
            a.hash === b.hash &&
            isObjectEqual(a.query, b.query) &&
            isObjectEqual(a.params, b.params)
        )
    } else {
        return false
    }
}
