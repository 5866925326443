<template>
    <input
        class="form-text"
        ref="inputRef"
        :value="formattedValue"
    >
</template>

<script>
import useCurrencyInput from 'vue-currency-input'

export default {
    name: 'CurrencyInput',
    props: {
        value: Number, // Vue 2: value
        options: Object
    },
	watch: {
		value: function(newVal) {
			this.setValue(newVal);
		}
	},
    setup (props) {
        const { formattedValue, inputRef, setValue } = useCurrencyInput(props.options)


        return { inputRef, formattedValue, setValue }
    }
}
</script>
