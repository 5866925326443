import Vue from 'vue';
import App from './App.vue';
import VueMq from "vue-mq";
import Transitions from "vue2-transitions";
import VModal from "vue-js-modal";
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VueTheMask from 'vue-the-mask'
import VueScrollTo from 'vue-scrollto';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';
import PortalVue from 'portal-vue'
import * as VueGoogleMaps from "vue2-google-maps";
import VueGtag from "vue-gtag";

import router from './router';
import store from './store';
import i18n from './i18n/i18n'
import "./helpers/filters";
import auth from "@snark/auth-vue";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import Pulse from "@/components/loader/Pulse.vue";
import Row from "@/components/grid/Row.vue";
import Column from "@/components/grid/Column.vue";

import FormContainer from "@/components/form/FormContainer.vue";
import FormView from "@/components/form/FormView.vue";
import Field from "@/components/form/Field.vue";
import GlobalErrors from "@/components/form/GlobalErrors.vue";
import Error from "@/components/form/Error.vue";

import Action from "@/components/Action.vue";
import Card from "@/components/Card.vue";
import Confirm from "@/components/modal/Confirm.vue";
import LinkLike from "@/components/LinkLike.vue";
import Icon from "@/components/Icon.vue";
import PopOver from "@/components/PopOver.vue";
import Success from "@/components/form/Success.vue";

import BusCard from "@/components/bus/BusCard.vue";

import Moment from "moment-timezone";
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
import ROUTES from "@/data/routes";
import TravelCard from "@/components/travels/TravelCard.vue";
import BusList from "@/components/bus/BusList.vue";
import TravelList from "@/components/travels/TravelList.vue";

Vue.use(PortalVue)
Vue.use(VueToast);
// @ts-ignore;
Vue.use(VueTelInput)
Vue.use(PerfectScrollbar)
Vue.use(VueScrollTo)
Vue.use(VueTheMask)
Vue.use(VueMq, {
    breakpoints: {
        mobile: 769,
        tablet: 1024,
        desktop: 1351,
        wide: 1500
    }
});

Vue.use(Transitions);
Vue.use(VModal, {dialog: true, dynamic: true, injectModalsContainer: true});
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY || "xxxxxx",
        libraries: "places,drawing,directions",
        region: "FR",
        language: "fr",
    }
});
// --- Analytics
if(process.env.VUE_APP_ENV !== "development") {
    Vue.use(VueGtag, {
        config: {id: process.env.VUE_APP_ANALYTICS_ID}
    }, router);
}

Vue.use(auth, {
    router,
    store,
    api: {
        base: process.env.VUE_APP_API_URL,
    },
    routes: {
        login: {
            path: "/connexion",
            name: ROUTES.LOGIN,
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
        },
        resetPassword: {
            path: "/nouveau-mot-de-passe",
            name: ROUTES.RESET_PASSWORD,
            component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue')
        },
    }

});

// import { extendMoment } from 'moment-range';
// extendMoment(moment);

Vue.component('pulse', Pulse);
Vue.component('row', Row);
Vue.component('column', Column);
Vue.component('action', Action);
Vue.component('card', Card);
Vue.component('form-container', FormContainer);
Vue.component('form-view', FormView);
Vue.component('field', Field);
Vue.component('global-errors', GlobalErrors);
Vue.component('error', Error);
Vue.component('confirm', Confirm);
Vue.component('link-like', LinkLike);
Vue.component('icon', Icon);
Vue.component('popover', PopOver);
Vue.component('success', Success);
Vue.component('bus-card', BusCard);
Vue.component('travel-card', TravelCard);
Vue.component('bus-list', BusList);
Vue.component('travel-list', TravelList);

function checkNotch() : void {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const ratio = window.devicePixelRatio || 1;
    const screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
    };

    // iPhone X Detection
    if (iOS && screen.width == 1125 && screen.height === 2436) {
        document.body.classList.add("iphonex");
    }
}

// Interceptor.define(router);

Vue.config.productionTip = false;

const locale = localStorage.getItem('locale');
if (locale) {
    moment.locale(locale);
    i18n.locale = locale;
} else {
    moment.locale(i18n.locale)
}

i18n.silentTranslationWarn = true;

Vue.prototype.$plural = (data: number | Array<any>) => (Array.isArray(data) ? (data.length) : (<number>data)) > 1 ? 's' : '';
Vue.prototype.$cdnUrl = process.env.VUE_APP_API_STATIC_URL;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    beforeCreate() {
        checkNotch();
    }
}).$mount('#app');
