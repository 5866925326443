"use strict";

const ROUTES:any = {
    LOGIN: 'login',
    LOG_AS: 'logas',
    RESET_PASSWORD: "reset-password",
    APP: {
        _ROOT: 'app',
        HOME: "home",
        TRAVELS_TO_DO: "sub-contractor-travels-to-do",
        AVAILABLE_BUSES: "sub-contractor-available-buses",
        SEARCH_TRAVEL: "sub-contractor-search-travel",
        MY_TRAVELS: "principal-my-travels",
        SEARCH_BUS: "principal-search-bus",
        TRAVEL: {
            _ROOT: "travel",
            MESSAGING: "travel-messaging",
            BILLING: "travel-billing",
            BUSES: "travel-buses"
        },
        BUS: {
            _ROOT: "bus",
            AVAILABILITIES: "bus-availabilities",
            DOCUMENTS: "bus-documents",
            PICTURES: "bus-pictures",
            TRAVELS: "bus-travels"
        },
        COMPANY: {
            _ROOT: "company",
            PROFILE: "company-profile",
            BUS_MANAGEMENT: "company-bus-management",
            TRAVEL_HISTORY: "company-travel-history"
        },
        PROFILE: "profile",
        NOTIFICATIONS: "notifications",
        MESSAGING: "messaging",
        LEGALS: "legals",
        CGV: "cgv",
        CONTACT: "contact"
    }
}

export default ROUTES;
