export enum COLLECTIONS {
    USER = "user",
    NOTIFICATION = "notification",
    COMPANY = "company",
    BRAND = "brand",
    MODEL = "model",
    DEPOT = "depot",
    BUS = "bus",
    AVAILABILITY = "availability",
    TRAVEL = "travel",
    PROPOSAL = "proposal",
    BLOG = "blog",
    LEGALS = "legals",
    PAYMENT = "payment",
}

export enum DBState {
    deleted = "deleted",
    disabled = "disabled",
}

export enum ErrorType {
    EmailNotValid = "Email.NotValid",
    PhoneNotValid = "Phone.NotValid",
    ForbiddenDeleteDepot = "Forbidden.DeleteDepot",
    UserNotFound = "User.NotFound",
    CompanyNotFound = "Company.NotFound",
    BrandNotFound = "Brand.NotFound",
    ModelNotFound = "Model.NotFound",
    DepotNotFound = "Depot.NotFound",
    BusNotFound = "Bus.NotFound",
    MessageNotFound = "Message.NotFound",
    ForbiddenAccess = "User.ForbiddenAccess",
    AvailabilityNotFound = "Availability.NotFound",
    TravelNotFound = "Travel.NotFound",
    ProposalNotFound = "Proposal.NotFound",
    ProposalNotValid = "Proposal.NotValid",
    AvailabilityNoLocation = "Availability.RequireDepotOrAddress",
    TravelCommandNotAuthorized = "Travel.CommandNotAuthorized",
}

export enum ErrorTypeFiles {
    ImageMissing = "Image.Missing",
    ImageNotValid = "Image.NotValid",
    ImageOverSized = "Image.OverSized",
    FileMissing = "File.Missing",
    FileNotValid = "File.NotValid",
    FileOverSized = "File.OverSized",
}

export enum NotificationType {
    UserForgotPassword = "user_forgot_password",
    UserSentMessageIntoProposal = "user_sent_message_into_proposal",
}

export enum NotificationLevel {
    info = "info",
    warning = "warning",
    alert = "alert",
}

export enum NotificationStatus {
    unread = "unread",
    read = "read",
    terminated = "terminated",
    archived = "archived",
}

export enum NotificationMode {
    email = "email",
    sms = "sms",
    push = "push",
    database = "database",
}

export enum PhoneOperatingSystems {
    IOS = "ios",
    Android = "android",
}

export enum TravelType {
    Mixt = "Mixt",
    Tourism = "Tourism",
    GrandTourism = "Grand-Tourism",
    Vip = "Vip"
}

export enum TravelPurpose {
	"event" = "event",
	"enterprise" = "enterprise",
	"students" = "students",
	"seniors" = "seniors",
	"recreation" = "recreation",
	"private" = "private",
	"other" = "other"
}

export enum Role {
    Collaborator = "Collaborator", // tout le monde est collaborateur pour l'instant
    Manager = "Manager",
}

export enum MessageType {
    Text = "Text",
    File = "File",
    System = "System",
}

export enum ProposalState {
    Created = "created",
    Proposed = "proposed",
    Accepted = "accepted",
    Refused = "refused",
    Closed = "closed",
    Canceled = "canceled",
}

export enum TravelState {
    Created = "created",
    Discussion = "discussion",
    Accepted = "accepted",
	PaidIn = "paidin",
	Transfered = "transfered",
	PaidOut = "paidout",
	Validated = "validatated",
    Terminated = "terminated",
    Evaluate = "evaluate",
    Canceled = "canceled",
    Archived = "archived",
    AmendmentPresented = "amendmentPresented", // avenant présenté
    AmendmentPresentedAfterPayment = "amendmentPresentedAfterPayment", // avenant présenté après paiement
    AmendmentValidated = "amendmentValidated", // avenant validé
    Disputed = "disputed", // en litige
}

export enum CustomerType {
    Association = "Association",
    CompanyCE = "Company-CE",
    SportClub = "Sport-Club",
    SeniorGroup = "Senior-Group",
    JuniorGroup = "Junior-Group",
    Other = "Other"
}

export enum ProposalCommand {
    updateState = "updateState",
    message = "message",
    propose = "propose",
    cancel = "cancel",
    accept = "accept",
    refuse = "refuse",
}

export enum TravelCommand {
    updateState = "updateState",
    update = "update",
    cancel = "cancel",
    pay = "pay",
    validate = "validate",
    evaluate = "evaluate",
    archive = "archive",
    addAmendment = "addAmendment", // Ajouter avenant
    cancelAmendment = "cancelAmendment", // Annulation avenant
    payAmendment = "payAmendment", // Regler l'avenant
    reportDispute = "reportDispute", // Signalement litige
}

export enum AvailabilityType {
    availability = "availability",
    emptyTravel = "emptyTravel"
}

export enum PaymentDueStatus {
	waiting = "waiting",
	scheduled = "scheduled",
	done = "done",
	error = "error"
}

export enum BookingRejectionReason {
	unavailable = "unavailable",
	tooLowPrice = "tooLowPrice",
	other = "other"
}
