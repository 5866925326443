"use strict";

import axios, {AxiosRequestConfig} from "axios";
import {ActionContext} from "vuex";
import {IRootState} from "@/data/types";
import {formatErrors} from "@/helpers/commons";
import clonedeep from "lodash.clonedeep";

export interface IConfig {
    url: string,
    data?: any,
    params?: object,
    headers?: object,
    responseType?: string,
    method: string
}

export interface IError {
    errors: any,
    formatedErrors: any
}

function query(cnf: IConfig) {
    cnf.url = process.env.VUE_APP_API_URL + cnf.url;
    return axios(<AxiosRequestConfig>cnf);
}

// function logout() {
//     store.commit('setToken', '', { root: true });
//     store.commit('setUser', {}, { root: true });
//     store.commit('setIsAuth', false, { root: true });
//     store.commit('setLoginVerified', true, { root: true });
//     router.push({name: ROUTES.LOGIN});
// }

const actions = {
    send({ commit, rootState, dispatch } : ActionContext<IRootState, IRootState>, { to, method, data = {}, params = {}, headers = {}, responseType } : { to: string, method: string, data: any, params: any, headers: any, responseType: string }) {
        return new Promise((resolve, reject) => {
            const config:IConfig = { method, url: to };

            headers["Authorization"] = "Bearer " + ((data && data.token) || rootState.token || rootState.auth?.token);

            data && (config.data = clonedeep(data));
            params && (config.params = params);
            headers && (config.headers = headers);
            responseType && (config.responseType = responseType);

            query(config)
                .then((response: any) => {
                    if (responseType === 'blob') {
                        resolve(response);
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((err: any) => {
                    const e:any = err?.response?.data;
                    const id = e && e[0] && e[0].id

                    if (id === "Auth.Token.Expired" || id === "Auth.Token.Invalid") {
                        localStorage.removeItem('auth_token');
                        document.location.reload();
                    }
                    reject(formatErrors(err?.response?.data, to));
                });
        });
    }
};

export default {
    actions
};
