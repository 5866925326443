<script>
    import VuePassword from "vue-password";
    import PasswordStrength from "@/components/PasswordStrength.vue";
    import VueTimepicker from "@/components/VueTimepicker.vue";
    import fr from "vuejs-datepicker/src/locale/translations/fr";
    import moment from "moment-timezone";
    import CurrencyInput from "@/components/CurrencyInput";
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/fr';
    import VueNumeric from '@/components/VueNumeric.vue';

    moment.locale('fr');

    export default {
        name: 'field',
        components: {
            CurrencyInput,
            VuePassword,
            PasswordStrength,
            VueTimepicker,
            DatePicker,
            VueNumeric
        },

        props: {
            name: String,
            type: {
                type: String,
                default: "text"
            },
            label: String,
            labelParams: {},
            placeholder: String,
            value: {},
            error: {},
            options: Array,
            size: Number,
            required: Boolean,
            readonly: Boolean,
            passwordStrength: {},
            labelIcon: String,
            icon: String,
            inputWidth: String,
            dateMin: Number,
            dateMax: Number,
            timeStep: Number,
            autocomplete: {type: Boolean, default: false},
            disclaimer: String
        },

        data() {
            return {
                keepInBounds: true,
                linkUrl: null,
                linkMenuIsActive: false,
                score: 0,
                fr,
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                }
            };
        },

        computed: {
            maskedDateValue() {
                return moment(this.value).format(this.$t('date.format'))
            },
            maskedValue() {
                return moment(this.value).format(this.$t('date.datetime'))
            },
            groupClass() {
                return "form-group"
                    + ((this.type === "checkbox") ? " form-check" : "")
                    + ((this.type === "radio") ? " form-rad" : "")
                    + (this.error ? " form-error" : "")
            },
            labelClass() {
                return "";
            },

            theLabel() {
                if (this.required) {
                    return this.$t(this.label, this.labelParams) + "<sup>*</sup>";
                } else {
                    return this.$t(this.label, this.labelParams);
                }
            },

			theValue() {
				// TODO: mettre partout dans le template le theValue à la place du value car sinon, les composnatns ne sont pas réactifs aux changements.
				return this.value;
			}
        },

        methods: {
            changed(e) {
                if (e && e.target) {
                    this.$emit("input", e.target.value);
                    this.$nextTick(() => {
                        this.$emit("change", e.target.value);
                    })
                } else {
                    this.$emit("input", e);
                    this.$nextTick(() => {
                        this.$emit("change", e);
                    })
                }
            },

            onPassword(password) {
                this.$emit("input", password);
                this.score = this.passwordStrength(password);
                this.$nextTick(() => {
                    this.$emit("change", password);
                })
            },

            checked(e) {
                console.log("checked: ", e.target.checked);
                this.$emit("input", e.target.checked);
            },

            radiohead(e) {
                console.log("radio: ", e.target.value);
                this.$emit("input", e.target.value);
            },

            blurEvent() {
                this.$emit('blur');
            },
            focusEvent() {
                this.$emit('focus');
            },

            dateChanged(e) {
                if (!e.length || e.length === this.$t('date.format').length) {
                    this.$emit("input", e ? moment(e, this.$t('date.format').valueOf()) : e);
                    this.$nextTick(() => {
                        this.$emit("change", e ? moment(e, this.$t('date.format').valueOf()) : e);
                    })
                }
            },

            timeChanged(e) {
                debugger;
                this.$emit("input", e);
                this.$nextTick(() => {
                    this.$emit("change", e);
                })
            },

            datetimeChanged(e) {
                debugger;
                if (!e.length || e.length === this.$t('date.datetime').length) {
                    this.$emit("input", e ? moment(e, this.$t('date.datetime').valueOf()) : e);
                    this.$nextTick(() => {
                        this.$emit("change", e ? moment(e, this.$t('date.datetime').valueOf()) : e);
                    })
                }
            },

            telChanged(e) {
                this.$emit("input", e);
                this.$nextTick(() => {
                    this.$emit("change", e);
                })
            },

            priceChanged(e) {
                this.$emit("input", e);
                this.$nextTick(() => {
                    this.$emit("change", e);
                })
            },
        },
    };

</script>

<template>
    <div v-if="type === 'checkbox'" :class="groupClass">
        <input type="checkbox" class="form-checkbox" :id="name" :name="name" @change="checked" :checked="value" @blur="blurEvent">
        <label v-if="label" class="form-check-label" :for="name">{{label}}</label>
    </div>
    <div v-else-if="type === 'radio'" :class="groupClass">
        <input type="radio" class="form-radio" :id="value" :name="name" @change="radiohead" :value="value" @blur="blurEvent">
        <label v-if="label" class="form-radio-label" :for="value">{{label}}</label>
    </div>
    <div v-else :class="groupClass">
        <div class="input">
            <div class="label" v-if="labelIcon">
                <icon :icon="labelIcon" />
                <label v-if="label" :class="labelClass" :for="name" v-html="theLabel"/>
            </div>
            <label v-if="!labelIcon && label" :class="labelClass" :for="name" v-html="theLabel"/>
            <div class="disclaimer" v-if="disclaimer">{{disclaimer}}</div>
            <icon v-if="icon" :icon="icon" />
            <div class="value" v-if="type === 'read'" v-html="value" />
<!--            <Datepicker v-if="type=='date'"-->
<!--                        :id="name"-->
<!--                        :name="name"-->
<!--                        input-class="form-text"-->
<!--                        :monday-first="true"-->
<!--                        :placeholder="placeholder || $t('date.format')"-->
<!--                        :language="fr"-->
<!--                        :format="$t('date.formatDatepicker')"-->
<!--                        @input="dateChanged"-->
<!--                        :value="value"/>-->
            <vue-numeric v-if="type === 'distance'"
                         class="form-text"
                         currency="km"
                         :precision="0"
                         round="ceil"
                         :decimal-separator="','"
                         :separator="'.'"
                         :id="name"
                         currency-symbol-position="suffix"
                         :name="name" @input="changed" :value="value" />
            <vue-tel-input :defaultCountry="'fr'"
                           :onlyCountries="['fr']"
                           v-if="type === 'tel'"
                           :id="name"
                           :name="name"
                           mode="international"
                           :placeholder="placeholder"
                           :inputOptions="{placeholder: '', autocomplete: 'off', id:name, name: name}"
                           :validCharactersOnly="true"
                           @input="telChanged"
                           :value="value" :size="size" @blur="blurEvent"/>
            <input :readonly="readonly" v-if="type === 'text'" :type="type" :id="name" :name="name"
                   :autocomplete="autocomplete ? 'on' : 'off'"
                   class="form-text" :placeholder="placeholder" @input="changed" :value="value" :size="size" @blur="blurEvent"  @focus="focusEvent"  @keydown.enter="$emit('enter')">
            <date-picker :lang="lang"
                         v-if="type === 'date'"
                         :input-attr="{id: name, name: name, placeholder: placeholder || $t('date.formatRead')}"
                         input-class="form-text"
                         :value="value"
                         value-type="timestamp"
                         :format="$t('date.format')"
                         @input="changed" />
            <date-picker :lang="lang"
                         v-if="type === 'datetime'"
                         type="datetime"
                         value-type="timestamp"
                         :input-attr="{id: name, name: name, placeholder: placeholder || $t('date.datetimeRead')}"
                         input-class="form-text"
                         :value="value"
                         :format="$t('date.datetime')"
                         @input="changed" />
            <currency-input v-if="type === 'price'" :value="theValue" @input="priceChanged" :options="{
                    locale: 'fr-FR',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                    precision: 2,
                    hideCurrencySymbolOnFocus: true,
                    hideGroupingSeparatorOnFocus: true,
                    hideNegligibleDecimalDigitsOnFocus: true,
                    autoDecimalDigits: false,
                    exportValueAsInteger: true,
                    autoSign: true,
                    useGrouping: true
                }" />
            <input :readonly="readonly" v-if="type === 'rawpassword'" :type="'password'" :id="name" :name="name"
                   :autocomplete="autocomplete ? 'on' : 'off'"
                   class="form-text" :placeholder="placeholder" @input="changed" :value="value" :size="size" @blur="blurEvent">
            <VuePassword
                v-if="type === 'password'"
                :classes="'form-text'"
                :strength="score"
                :strengthMessages="['', '', '', '', '']"
                @input="onPassword" :value="value"
                type="password"
            />
            <div v-if="type === 'select'" class="select">
                <select :id="name" :name="name" class="form-select" @change="changed" :value="value"
                        :readonly="readonly"
                        :placeholder="placeholder"
                        :size="size">
                    <option v-for="(option, index) in options" :key="'option' + option.value + index" :value="option.value"
                            :disabled="option.disabled"
                            :selected="option.selected">{{option.label}}
                    </option>
                </select>
                <icon class="iconSelect" icon="triangle2" />
            </div>
            <textarea v-if="type === 'textarea'" :readonly="readonly" :id="name" :name="name" class="form-textarea" @input="changed"
                      :value="value" :placeholder="placeholder" :cols="size" @blur="blurEvent"/>
            <vue-timepicker v-if="type === 'time'"
                            auto-scroll
                            manual-input
                            hide-clear-button
                            :minute-interval="10"
                            :disabled="readonly"
                            input-class="form-text"
                            :input-width="inputWidth"
                            :format="$t('date.time2')"
                            :value="value"
                            @input="timeChanged"
                            />
        </div>
        <password-strength v-if="type === 'password'" :password="value" />
        <collapse-transition mode="out-in" :duration="125">
            <div class="meta" v-if="error">
                <error :error="error"/>
            </div>
        </collapse-transition>
    </div>
</template>

<style lang="scss">

</style>
