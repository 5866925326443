"use strict";

export function showModal(this: any, modalName: string, modalParams?:any) {
    this['show' + modalName] = true;

    this.$nextTick(() => {
        this.$modal.show(modalName, modalParams);
    });
}
export function hideModal(this: any, modalName: string, modalParams?:any) {
    this['show' + modalName] = false;
    this.$nextTick(() => {
        this.$modal.hide(modalName, modalParams);
    });
}
