import Vue from 'vue';
import Vuex from 'vuex';
import query from "./query";
import actions from "./actions";
import {IBrandDB, ICompanyDB, IDepotDB, IModelDB, IRootState,} from "@/data/types";
import clonedeep from "lodash.clonedeep";
import moment from "moment-timezone";
import i18n from "@/i18n/i18n";
import {TravelType} from "@/data/enums";

Vue.use(Vuex);

export default new Vuex.Store({
    state: <IRootState>{
        currentUser: {},
        currentCompany: {},
        configuration: {},
        notifications: [],
        token: "",
        totalUnreadMessages: 0,
        totalUnreadNotifications: 0,
        savedLastTravel: "",
		newestSystemMessage : []
    },
    mutations: {
        setUser(state: IRootState, value: any) {
            state.currentUser = value;
        },
        setCurrentUser(state: IRootState, value: any) {
            state.currentUser = value;
        },
		setNewestSystemMessage(state: IRootState, value: any) {
            state.newestSystemMessage = value;
        },
        setCurrentCompany(state: IRootState, value: any) {
            state.currentCompany = value;
        },
        changeCompany(state: IRootState, value: any) {
            state.currentCompany = value;
        },
        setConfiguration(state: IRootState, value: any) {
            state.configuration = value;
        },
        setNotifications(state: IRootState, value: any) {
            state.notifications = value.notifications;
            state.totalUnreadNotifications = value.totalUnread;
        },
        setMessageNumber(state: IRootState, value: any) {
            state.totalUnreadMessages = value.count;
        },
        setLastTravel(state: IRootState, value: string) {
            state.savedLastTravel = value;
        },
    },
    getters: {
        customerTypes() {
            return [
                {
                    label: "Association",
                    value: "Association"
                },
                {
                    label: "Entreprise/CE",
                    value: "Company-CE"
                },
                {
                    label: "Club sportif",
                    value: "Sport-Club"
                },
                {
                    label: "Groupe sénior",
                    value: "Senior-Group"
                },
                {
                    label: "Groupe jeunesse",
                    value: "Junior-Group"
                },
                {
                    label: "Autre",
                    value: "Other"
                },
            ]
        },
        typologyOptions() {
            return[
                {
                    label: "Mixte",
                    value: TravelType.Mixt
                },
                {
                    label: "Tourisme",
                    value: TravelType.Tourism
                },
                {
                    label: "Grand Tourisme",
                    value: TravelType.GrandTourism
                },
                {
                    label: "VIP",
                    value: TravelType.Vip
                },
            ]
        },
        fullbusFeePerKm(state: IRootState) {
            return state.configuration.fullbusFeePerKm;
        },
        currentUser(state: IRootState) {
            return state.currentUser;
        },
		newestSystemMessage(state: IRootState) {
            return state.newestSystemMessage;
        },
        currentCompany(state: IRootState) {
            return state.currentCompany;
        },
        companies(state: IRootState) {
            return state.currentUser.companies;
        },
        buses(state: IRootState) {
            return state.currentUser.buses;
        },
        brands(state: IRootState) {
            return state.configuration?.brands || [];
        },
        depots(state: IRootState) {
            return state.configuration?.depots || [];
        },
        currentDepots(state: IRootState) {
            return state.configuration?.depots.filter((d:IDepotDB) => d.companyId=== state.currentCompany._id) || [];
        },
        models(state: IRootState) {
            return state.configuration?.models || [];
        },
        verifyST(state: IRootState) {
            return (creatorId: string, bookingTravel: boolean) => {
				if(bookingTravel) {
					return true;
				}
				else {
					return creatorId && state.currentUser.companies?.filter((c: ICompanyDB) => {
						return c._id !== creatorId;
					}).length === state.currentUser.companies.length;
				}
            };
        },
        verifyDO(state: IRootState) {
            return (creatorId: string, bookingTravel: boolean) => {
				if(bookingTravel) {
					return false;
				}
				else {
					return creatorId && state.currentUser.companies?.filter((c: ICompanyDB) => {
						return c._id === creatorId;
					}).length >= 1;
				}
            };
        },
        getCompany(state: IRootState) {
            return (companyId: string) => companyId && state.currentUser.companies.find((c:ICompanyDB) => c._id === companyId);
        },
        getCompanyDetail(state: IRootState) {
            return (companyId: string) => {
                return (companyId && state.configuration.companies.find((c:ICompanyDB) => c._id === companyId)) || null;
            }
        },
        brandsOptions(state: IRootState) {
            return state.configuration?.brands.map((i: IBrandDB) => {
                return {
                    label: i.name,
                    value: i._id
                }
            }) || [];
        },
        depotsOptions(state: IRootState) {
            return state.configuration?.depots.map((i: IDepotDB) => {
                return {
                    label: i.name,
                    value: i._id
                }
            }) || [];
        },
        modelsOptions(state: IRootState) {
            return state.configuration?.models.map((i: IModelDB) => {
                return {
                    label: i.name,
                    value: i._id,
                    brandId: i.brandId
                }
            }) || [];
        },
        getBrandName(state: IRootState) {
            return (brandId: string) => {
                const b: IBrandDB = state.configuration.brands.find((b: IBrandDB) => b._id === brandId)
                return b?.name || "";
            }
        },
        getDepotName(state: IRootState) {
            return (depotId: string) => {
                const d: IDepotDB = state.configuration.depots.find((d: IDepotDB) => d._id === depotId)

                return d?.name || "";
            }
        },
        getModelName(state: IRootState) {
            return (modelId: string) => {
                const m: IModelDB = state.configuration.models.find((m: IModelDB) => m._id === modelId)

                return m?.name || "";
            }
        },
        serializeBus(state: IRootState) {
            return (bus: any) => {
                const copy = clonedeep(bus);

                if (copy.pictures?.length) {
                    copy.pictures = copy.pictures.filter((p: any) => p.name && p.path);
                }
                if (copy.documents?.length) {
                    copy.documents = copy.documents.filter((p: any) => p.name && p.path);
                }

                return copy;
            }
        },
        serializeTravel(state) {
            return (travel: any) => {
                const copy = clonedeep(travel);

                // @ts-ignore;
                if (typeof copy.from.datetime !== "number") {
                    // @ts-ignore;
                    if (typeof travel.from.datetime === "string") {
                        copy.from.datetime = moment(travel.from.datetime, <string>i18n.t('date.datetime')).valueOf();
                    } else {
                        copy.from.datetime = travel.from.datetime.valueOf();
                    }
                }
                // @ts-ignore;
                if (typeof copy.to.datetime !== "number") {
                    // @ts-ignore;
                    if (typeof travel.to.datetime === "string") {
                        copy.to.datetime = moment(travel.to.datetime, <string>i18n.t('date.datetime')).valueOf();
                    } else {
                        copy.to.datetime = travel.to.datetime.valueOf();
                    }
                }

                if (copy.documents?.length) {
                    copy.documents = copy.documents.filter((p: any) => p.name && p.path);
                }

                if (copy.distanceKm) {
                    copy.distance = copy.distanceKm * 1000;
                    delete copy.distanceKm;
                } else {
                    copy.distance = 0;
                }

                return copy;
            }
        },
    },
    actions: {

	},
    modules: {
        query,
        actions
    }
});

